import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '@pdl/app/components/images/ProfileImage';
import DateHelper from '@pdl/app/util/DateHelper';
import {
  logoutTriggerAction,
  loginTriggerAction
} from '@pdl/app/modules/auth/AuthActions';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import LanguageSwitcher from '@pdl/app/modules/locale/components/LanguageSwitcher';

class AppTopBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      verificationInfo: props.profile.kycStatus !== 'verified'
    };
  }

  logoutUser() {
    this.props.userLoggedOut(this.props.token);
  }

  render() {
    const { profile } = this.props;

    const profileImg = <ProfileImage profile={profile} />;

    let verificationInfoBar = <div />;

    if (this.state.verificationInfo) {
      verificationInfoBar = (
        <div className="container-fluid topbar-verification-container">
          <img
            src={require('@pdl/app/assets/img/close-icon-gray.svg')}
            style={{
              width: 15,
              position: 'absolute',
              right: 20,
              cursor: 'pointer'
            }}
            onClick={() => this.setState({ verificationInfo: false })}
            alt=""
          />
          <p style={{ fontSize: 14, color: 'white', textAlign: 'center' }}>
            <Trans>Your account is not verified yet. Please stay tuned.</Trans>
            {/* Please verify your account.{' '}
            <Link to="/order-card" style={{ color: 'rgb(173, 138, 78)' }}>
              Click here
            </Link>{' '}
            to have your confirmation link via email. */}
          </p>
        </div>
      );
    }
    return (
      <>
        <div className="dashboard-menu-top" style={{ background: 'white' }}>
          <div className="container-fluid">
            <div className="topbar-container">
              <div>
                <a className="" href="/">
                  <img
                    src={require('@pdl/app/assets/img/pdl-logo.svg')}
                    className="topbar-logo"
                    style={{ maxWidth: 200 }}
                    alt=""
                  />
                </a>
              </div>

              <div className="topbar-user-info-wrapper">
                <div className="good-afternoon-image-wrapper">
                  <div className="greeting-holder">
                    <p className="good-afternoon">
                      <Trans>{DateHelper.getGreeting()}</Trans>
                    </p>
                    <p className="mobile-full-name">
                      {this.props.profile.fullName}
                    </p>
                  </div>
                  <div className="profile-image-holder">{profileImg}</div>
                </div>

                <div className="last-login-container">
                  <p style={{ fontSize: 24 }}>{this.props.profile.fullName}</p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    <Trans>LAST LOGIN: </Trans>
                    {DateHelper.date(profile.lastLogin)}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Link to="/notifications">
                    <img
                      src={require('@pdl/app/assets/img/notification-icon-black.svg')}
                      style={{ width: 20, marginRight: 20 }}
                      alt=""
                    />
                  </Link>
                  {/* <Link to="/">
                    <img
                      src={require('@pdl/app/assets/img/search-icon-black.svg')}
                      style={{ width: 20 }}
                      alt=""
                    />
                  </Link> */}
                  <div>
                    <LanguageSwitcher />
                  </div>
                  <div
                    onClick={() => this.logoutUser()}
                    style={{
                      color: '#383838',
                      marginLeft: 30,
                      cursor: 'pointer'
                    }}
                  >
                    <Trans>Logout</Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {verificationInfoBar}
      </>
    );
  }
}
const mapStateToProps = (state: { auth: { token: any; profile: any } }) => {
  return {
    token: state.auth.token,
    profile: state.auth.profile
  };
};

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: {} }): void;
  (arg0: { type: string; payload: { token: any; account: any } }): void;
}) => {
  return {
    userLoggedOut: () => {
      return dispatch(logoutTriggerAction());
    },
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTopBar);
