/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginTriggerAction } from '@pdl/app/modules/auth/AuthActions';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppCheckboxInput
} from '@pdl/app';
import AppSelect from '@pdl/app/components/elements/form/AppSelect';
import CountryModel from '@pdl/app/logic/model/CountryModel';
import AppDatePicker from '@pdl/app/components/elements/form/date/AppDatePicker';
import { AppButton } from '@pdl/app/components';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import PrivacyPolicy from '@pdl/app/modules/legal/PrivacyPolicy';
import TermsAndConditions from '@pdl/app/modules/legal/TermsAndConditions';
import { Trans } from 'react-i18next';
import LocationService from '@pdl/app/logic/services/location/LocationService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import i18n from 'i18n';
import AppNumberField from '@pdl/app/components/elements/form/AppNumberField';
import EditProfileFormModel from '@pdl/app/modules/profile/models/EditProfileFormModel';
import RegistrationModel from '../../models/RegistrationModel';
import ValidationErrors from '@pdl/app/components/elements/form/AppValidationErrors';

export class CreateAccount extends Component<any, any> {
  formHandler: FormHandler;

  constructor(props) {
    super(props);

    const data = {
      birthDate: null,
      firstName: '',
      lastName: '',
      password: '',
      repeatedPassword: '',
      addressOne: '',
      addressTwo: '',
      houseNameNumber: '',
      postCode: '',
      city: '',
      mobile: '',
      gdprAgreed: true,
      funds: {
        salary: false,
        gift: false,
        benefits: false,
        savings: false
      }
    };

    // data = {
    //   birthDate: null,
    //   firstName: 'Test',
    //   lastName: 'Testing',
    //   password: 'pannovate1.',
    //   repeatedPassword: 'pannovate1.',
    //   addressOne: 'Street',
    //   houseNameNumber: '33',
    //   postCode: 'NW7 3NE',
    //   city: 'London',
    //   mobile: '004479654654654'
    // };

    this.formHandler = new FormHandler(
      this,
      new RegistrationModel(data),
      {
        hashValid: false,
        title: EditProfileFormModel.getTitles()
      },
      {
        showPrivacyPolicyModal: false,
        isPrivacy: true,
        countries: []
      }
    );
  }

  componentDidMount() {
    const { hash } = this.props.match.params;
    this._checkHash(hash);

    LocationService.getCountries()
      .then(countries => {
        if (!countries) countries = [];
        const defaultCountry = countries.find(item => item.isDefault);
        this.setState({ countries, selectedItem: defaultCountry });
      })
      .catch();
  }

  onSubmitSuccess(data: any) {
    let country: any;

    if (this.state.record.countryId !== '') {
      country = this.state.countries.find(
        x => x.id === this.state.record.countryId
      );
    }

    const countryCode = country && `+${country.phoneCode}`;
    const countryCodeLength = this.state.record.mobile.substring(
      0,
      countryCode.length
    );

    // if (!this.state.record.mobile.includes(countryCode)) {
    if (countryCode !== countryCodeLength) {
      ToastHelper.show(
        `${i18n.t('Please enter valid phone code')} ${countryCode}`,
        'error'
      );
      return;
    }

    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }
  }

  onClose() {
    this.setState({
      showPrivacyPolicyModal: false
    });
  }

  openModal() {
    this.setState({
      showPrivacyPolicyModal: true
    });
  }

  openPrivacyModal() {
    this.setState(
      {
        isPrivacy: true
      },
      () => {
        this.openModal();
      }
    );
  }

  openTCModal() {
    this.setState(
      {
        isPrivacy: false
      },
      () => {
        this.openModal();
      }
    );
  }

  async _checkHash(hash: string) {
    const result = await new RegistrationModel().validateRegistrationHash(hash);
    if (!result) {
      this.props.history.replace('/');
      return;
    }
    const { record } = this.state;
    this.formHandler.setState({
      record: {
        ...record,
        email: result.email,
        hash: result.hash
      }
    });
  }

  render() {
    const { formHandler } = this;

    if (!formHandler) return null;

    let privacyContent = <PrivacyPolicy />;
    if (!this.state.isPrivacy) {
      privacyContent = <TermsAndConditions />;
    }

    return (
      <div>
        <div className="verification-image-signup" />
        <div className="signUp-wrapper create-account">
          <Link
            to="/login"
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontSize: 14,
              textAlign: 'center'
            }}
          >
            <Trans>Already have an account? Sign in</Trans>
          </Link>
        </div>
        <div className="container login-container create-account-container">
          <img
            src={require('@pdl/app/assets/img/pdl-logo.svg')}
            className="login-logo"
            style={{ maxWidth: 200 }}
            alt="logo"
          />
          <div className="row">
            <div className="col-md login-holder">
              <h4
                className="login-title "
                style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
              >
                <Trans>Create your account</Trans>
              </h4>
              <FormSubmitWrapper hideSubmit formHandler={formHandler} to="/">
                <div className="">
                  <div
                    className="create-account-form-wrapper"
                    style={{ marginTop: 10 }}
                  >
                    <div style={{ width: '100%' }}>
                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <AppSelect
                            materialProps={{
                              fullWidth: true
                            }}
                            className="create-account-input-group"
                            label={i18n.t('Title')}
                            options={this.state.title}
                            name="title"
                            value={this.state.record.title}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper" />
                      </div>
                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            tabIndex={1}
                            label={i18n.t('First Name')}
                            type="text"
                            name="firstName"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.firstName}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Last Name')}
                            type="text"
                            name="lastName"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.lastName}
                            handler={formHandler}
                          />
                        </div>
                      </div>
                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Password')}
                            type="password"
                            name="password"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.password}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Repeat password')}
                            type="password"
                            name="repeatedPassword"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.repeatedPassword}
                            handler={formHandler}
                          />
                        </div>
                      </div>
                      <div className="container">
                        <p style={{ fontSize: 12 }} className="pb-3">
                          <Trans>
                            Password must be at least 8 alphanumeric characters
                            or longer and must include uppercase, lowercase and
                            at least one number or special character (such as
                            !@#$%)
                          </Trans>
                        </p>
                      </div>
                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Address Line One')}
                            type="text"
                            name="addressOne"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.addressOne}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Address Line Two')}
                            type="text"
                            name="addressTwo"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.addressTwo}
                            handler={formHandler}
                          />
                        </div>
                      </div>

                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('House number/name')}
                            type="text"
                            name="houseNameNumber"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.houseNameNumber}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Post Code')}
                            type="text"
                            name="postCode"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.postCode}
                            handler={formHandler}
                          />
                        </div>
                      </div>
                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('City/Town')}
                            type="text"
                            name="city"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.city}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <AppSelect
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Country')}
                            textFieldProps={{
                              label: i18n.t('Country'),
                              InputLabelProps: {
                                shrink: true
                              }
                            }}
                            model={CountryModel}
                            name="countryId"
                            value={this.state.record.countryId}
                            handler={formHandler}
                          />
                        </div>
                      </div>

                      <div className="fields-wrapper">
                        <div className="create-account-input-wrapper">
                          {/* <InputField
                            className="create-account-input-group"
                            materialProps={{
                              fullWidth: true
                            }}
                            label={i18n.t('Mobile phone')}
                            type="text"
                            name="mobile"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.record.mobile}
                            handler={formHandler}
                          /> */}
                          <AppNumberField
                            className="create-account-input-group"
                            format="+##############"
                            // mask="_"
                            label={i18n.t('Mobile number')}
                            type="tel"
                            name="mobile"
                            value={this.state.record.mobile}
                            handler={formHandler}
                          />
                        </div>
                        <div className="create-account-input-wrapper">
                          <AppDatePicker
                            className="create-account-input-group"
                            id="js-date-picker"
                            value={this.state.record.birthDate}
                            name="birthDate"
                            label={i18n.t('Date of Birth')}
                            style={{ width: '100%' }}
                            handler={formHandler}
                            datePickerProps={{
                              maxDate: new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 18
                                )
                              )
                            }}
                            // children={(
                            //   <div className="time-picker-container"></div>
                            // )}
                          />
                        </div>
                      </div>
                      <div className="create-account-input-wrapper">
                        <div className="source-of-funds">
                          <span
                            style={{
                              color: 'rgb(155, 154, 155)',
                              fontSize: 14
                            }}
                          >
                            <Trans>Please confirm source of funds</Trans>
                          </span>
                          <div className="row">
                            <div
                              className="col-sm-3"
                              style={{ paddingRight: 30 }}
                            >
                              <AppCheckboxInput
                                labelText={i18n.t('Salary')}
                                value={this.state.record.funds.salary}
                                name="funds.salary"
                                handler={this.formHandler}
                              />
                            </div>
                            <div
                              className="col-sm-3"
                              style={{ paddingRight: 30 }}
                            >
                              <AppCheckboxInput
                                labelText={i18n.t('Gift')}
                                value={this.state.record.funds.gift}
                                name="funds.gift"
                                handler={this.formHandler}
                              />
                            </div>
                            <div
                              className="col-sm-3"
                              style={{ paddingRight: 30 }}
                            >
                              <AppCheckboxInput
                                labelText={i18n.t('Benefits')}
                                value={this.state.record.funds.benefits}
                                name="funds.benefits"
                                handler={this.formHandler}
                              />
                            </div>
                            <div
                              className="col-sm-3"
                              style={{ paddingRight: 30 }}
                            >
                              <AppCheckboxInput
                                labelText={i18n.t('Savings')}
                                value={this.state.record.funds.savings}
                                name="funds.savings"
                                handler={this.formHandler}
                              />
                            </div>
                          </div>
                          <ValidationErrors
                            name={"funds"}
                            handler={this.formHandler}
                          />
                        </div>
                        <div style={{ marginTop: 30, position: 'relative' }}>
                          <AppCheckboxInput
                            name="privacyPolicyAgreed"
                            labelText={
                              <div>
                                <Trans>
                                  I agree to the processing of my personal
                                  information in accordance with the
                                </Trans>{' '}
                                <a
                                  onClick={this.openPrivacyModal.bind(this)}
                                  style={{ color: '#ad8a4e' }}
                                  href="javascript:void(0)"
                                >
                                  <Trans>Privacy Policy</Trans>
                                </a>
                              </div>
                            }
                            value={this.state.record.privacyPolicyAgreed}
                            handler={this.formHandler}
                          />
                          <AppCheckboxInput
                            name="termsAndConditionsAgreed"
                            id="squaredTwo3"
                            htmlFor="squaredTwo3"
                            labelText={
                              <div>
                                <Trans>I understand and accept the PDL</Trans>{' '}
                                <a
                                  href="javascript:void(0)"
                                  onClick={this.openTCModal.bind(this)}
                                  style={{ color: '#ad8a4e' }}
                                >
                                  <Trans>Terms and Conditions</Trans>
                                </a>
                              </div>
                            }
                            value={this.state.record.termsAndConditionsAgreed}
                            handler={this.formHandler}
                            // onClick={() => this.handleCheckbox1()}
                          />
                          <ModalDialog
                            show={this.state.showPrivacyPolicyModal}
                            onClose={() => [this.onClose.bind(this)]}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                maxWidth: 1000,
                                backgroundColor: 'white',
                                position: 'relative'
                              }}
                            >
                              <a
                                style={{
                                  position: 'absolute',
                                  top: 20,
                                  right: 20
                                }}
                                href="javascript:void(0)"
                                onClick={this.onClose.bind(this)}
                              >
                                <i
                                  className="fa fa-times-circle-o"
                                  aria-hidden
                                  style={{
                                    color: '#ad8a4e',
                                    fontSize: 36
                                  }}
                                />
                              </a>
                              {privacyContent}
                            </div>
                          </ModalDialog>
                          {/* <AppCheckboxInput
                            name="gdprAgreed"
                            id="squaredTwo4"
                            htmlFor="squaredTwo4"
                            labelText="GDPR Compliance text (to be provided)"
                            value={this.state.record.gdprAgreed}
                            // value={this.state.checkbox2}
                            handler={this.formHandler}
                            // onClick={() => this.handleCheckbox1()}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 30,
                    marginBottom: 30
                  }}
                >
                  <AppButton
                    type="submit"
                    isLoading={this.state.isLoading}
                    className="gold-button"
                    style={{ maxWidth: 400, width: '100%' }}
                  >
                    <Trans>Next</Trans>
                  </AppButton>
                </div>
                <div className="signUp-wrapper create-account bottom">
                  <Link
                    to="/login"
                    style={{
                      color: '#fff',
                      textDecoration: 'none',
                      fontSize: 14,
                      textAlign: 'center'
                    }}
                  >
                    <Trans>Already have an account? Sign in</Trans>
                  </Link>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: { type: string; payload: { token: any } }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(CreateAccount);
