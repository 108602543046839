import React from 'react';
import { connect } from 'react-redux';
import { AppButton } from '@pdl/app';
import ToastHelper from '@pdl/app/util/ToastHelper';
import NumberHelper from '@pdl/app/util/NumberHelper';
import ContentEditable from 'react-contenteditable';
import CurrencyHelper from '@pdl/app/util/CurrencyHelper';
import Config from '@pdl/app/config';
import { Trans } from 'react-i18next';
import i18n from '../../../../../i18n';
import SendMoneyService from '../SendMoneyService';
import AccountService from '../../account/AccountService';

class EnterAmount extends React.Component<any, any> {
  inputField: any;
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.state = {
      amount: '',
      note: ''
    };
  }

  componentDidMount() {
    if(this.inputField) {
      this.inputField.focus();
    }
  }

  onChange(e: any) {
    const mainAccount = AccountService.getMainAccount(this.props.accounts);

    let textValue = e.target.value;

    if (textValue && textValue > Config.sendMoney.max) {
      textValue = String(Config.sendMoney.max);

      ToastHelper.show(
        `${i18n.t('The maximum transfer value is')} ${NumberHelper.currency(
          Config.sendMoney.max,
          mainAccount.currency
        )}.`,
        'error'
      );
    }

    if (textValue.length > 1 && textValue[0] === 0) {
      textValue = `${+parseFloat(textValue)}`;
    }

    if (textValue.split('.').length > 2) {
      this.setState({
        amount: this.state.amount
      });
      return;
    }

    if (textValue.length) {
      const regexp = /^\d+(\.\d{0,2})?$/;

      // returns true
      if (!regexp.test(textValue)) {
        this.setState({
          amount: this.state.amount
        });
        return;
      }
    }

    let value = parseFloat(textValue);

    if (!value) value = 10;

    this.setState({
      amount: textValue
    });
  }

  onFocus() {
    if (this.state.amount === '0') {
      this.setState({ amount: '' });
    }
  }

  onBlur() {
    if (this.state.amount === '') {
      this.setState({ amount: '0' });
    }
  }

  submitTransfer = (user: any) => {
    const mainAccount = AccountService.getMainAccount(this.props.accounts);

    if (this.state.amount < Config.sendMoney.min) {
      ToastHelper.show(
        `${i18n.t('The minimum transfer value is')} ${NumberHelper.currency(
          Config.sendMoney.min,
          mainAccount.currency
        )}.`,
        'error'
      );
    } else if (this.state.amount > Config.sendMoney.max) {
      ToastHelper.show(
        `${i18n.t('The maximum transfer value is')} ${NumberHelper.currency(
          Config.sendMoney.max,
          mainAccount.currency
        )}.`,
        'error'
      );
    } else {
      this._actualTransfer(
        user,
        mainAccount.currency,
        this.state.amount,
        this.state.note
      );
    }
  };

  async _actualTransfer(user: any, currency: any, amount: any, note: any) {
    try {
      const hash = await SendMoneyService.triggerTransfer(
        user,
        currency,
        Number(this.state.amount),
        this.state.note
      );

      const data = {
        hash,
        user,
        currency,
        amount,
        note
      };

      this.setState(
        {
          amount: '',
          note: ''
        },
        () => {
          this.props.nextStep();
          this.props.dataForTransfer(data);
        }
      );
    } catch (e) {
      ToastHelper.show(e.message, 'error');
    }
  }

  render() {
    const mainAccount = AccountService.getMainAccount(this.props.accounts);

    return (
      <>
        <h4 className="text-center">
          <Trans>Enter amount</Trans>
        </h4>
        <div className="d-flex align-items-center flex-column justify-content-center">
          <span className="amount-input">
            <ContentEditable
              html={this.state.amount} // innerHTML of the editable div
              disabled={false} // use true to disable editing
              onChange={e => this.onChange(e)} // handle innerHTML change
              tagName="span" // Use a custom HTML tag (uses a div by default)
              onFocus={() => this.onFocus()}
              onBlur={() => this.onBlur()}
              innerRef={_ref => this.inputField = _ref }
            />
            {CurrencyHelper.getCurrencySymbol(mainAccount.currency)}
          </span>

          <textarea
            placeholder={i18n.t('Add a Note')}
            style={{
              marginTop: 10,
              marginBottom: 10,
              padding: 10,
              textAlign: 'left',
              width: '100%',
              height: '100px'
            }}
            onChange={e => this.setState({ note: e.target.value })}
          />
        </div>

        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: 100 }}
        >
          <AppButton onClick={() => this.submitTransfer(this.props.user)}>
            <Trans>Transfer</Trans>
          </AppButton>
          <AppButton onClick={() => this.props.previousStep()}>
            <Trans>back</Trans>
          </AppButton>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { accounts: any; auth: { profile: any } }) => {
  return {
    profile: state.auth.profile,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(EnterAmount);
