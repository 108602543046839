import React, { Component } from 'react';
import Chart from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import NumberHelper from '@pdl/app/util/NumberHelper';
import { AppList, AppButton } from '@pdl/app';
import $ from 'jquery';
import { connect } from 'react-redux';
import _ from 'lodash';
import Api from '@pdl/app/logic/api/Api';
import CardService from '../card/CardService';
import TransactionItem from './components/TransactionItem';
import TransactionMerchantsModel from './models/TransactionMerchantsModel';
import TransactionCategoriesModel from './models/TransactionCategoriesModel';
import TransactionModel from './models/TransactionModel';
import ChartModel from './models/ChartModel';
import DashboardHeader from '../dashboard/components/DasboardHeader';
import AccountService from '../account/AccountService';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

class StatementsPage extends Component {
  constructor(props) {
    super(props);
    this.cachedItems = {};
    this.state = {
      startDate: null,
      endDate: null,
      term: '',
      termTemp: '',
      transactionFilterOption: ['all', 'merchant', 'category'],
      chartFilterOption: ['week', 'month', 'year'],
      data: [],
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      selected: '',
      transactions: [],
      items: [],
      type: 'all',
      period: 'week',
      weekly: true,
      monthly: false,
      yearly: false,
      loading: false,
      totalSpent: '',
      totalDonated: '',
      periods: this._getPeriods()
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);

    this.onChangeDelayed = _.debounce(this.handleChangeTerm, 200);
  }

  _getPeriods() {
    return [
      {
        slug: 'week',
        text: 'Weekly',
        active: true
      },
      {
        slug: 'month',
        text: 'Monthly',
        active: false
      },
      {
        slug: 'year',
        text: 'Yearly',
        active: false
      }
    ];
  }

  componentDidMount() {
    this.renderStatementsChart();
    this._loadData();
    // this.setState({
    //   selected: this.state.transactionFilterOption[0],
    //   period: this.state.chartFilterOption[0]
    // });

    $('.calendarFrom').click(function(event) {
      event.preventDefault();
      $('.dateFrom').click();
    });
    $('.calendarTo').click(function(event) {
      event.preventDefault();
      $('.dateTo').click();
    });
  }

  async _loadData(silent = false) {
    if (this.state.loading) return;

    if (!silent) {
      this.setState({
        loading: true
      });
    }
    const chartData = await new ChartModel().getChartData(
      this.state.periods.find(x => x.active).slug
    );
    try {
      this.setState({
        loading: false,
        data: chartData.chart.data,
        labels: chartData.chart.labels,
        totalSpent: chartData.totalSpent,
        totalDonated: chartData.totalDonated
      });
    } catch (e) {
      console.log(e);
    }

    this.renderStatementsChart();
  }

  handleChangeFrom(dateFrom) {
    this.setState(
      {
        startDate: dateFrom ? moment(dateFrom) : null
      },
      () => {
        this.refreshTransactions();
      }
    );
  }

  handleChangeTo(dateTo) {
    this.setState(
      {
        endDate: dateTo ? moment(dateTo) : null
      },
      () => {
        this.refreshTransactions();
      }
    );
  }

  handleChangeTerm() {
    this.setState(
      {
        term: this.state.termTemp
      },
      () => {
        this.refreshTransactions();
      }
    );
  }

  refreshTransactions() {
    if (this._list) {
      this._list._reset().then(() => {
        this._list._refresh();
      });
    }
  }

  _changePeriod(period) {
    const periods = [...this.state.periods];
    periods.map(p =>
      p.slug === period.slug ? (p.active = true) : (p.active = false)
    );

    this.setState(
      {
        periods
      },
      () => {
        this._loadData();
      }
    );
  }

  getTransactionOption() {
    const items = [];
    let i;

    for (i = 0; i < this.state.transactionFilterOption.length; i++) {
      const val = this.state.transactionFilterOption[i];
      items.push(
        <li
          key={`transactionFilterOption${i}`}
          className={this.state.type === val ? 'activeOption' : null}
          onClick={() => this.optionClicked(val)}
          style={{ padding: '5px 20px', cursor: 'pointer', fontSize: 12 }}
        >
          <span className="option-item">
            {this.state.transactionFilterOption[i]}
          </span>
        </li>
      );
    }
    return items;
  }

  renderStatementsChart() {
    try {
      const ctx = document
        .getElementById('numberStatementsChart')
        .getContext('2d');
      const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);

      const startColor = '#ad8a4e';
      const mainColor = '#383838';

      gradientStroke.addColorStop(0, startColor);
      gradientStroke.addColorStop(1, mainColor);
      const config = {
        type: 'line',
        data: {
          labels: this.state.labels,
          datasets: [
            {
              yAxisID: 'CustomersCountAxis',
              data: this.state.data,
              backgroundColor: 'rgba(124, 197, 118, 0.0)',
              borderWidth: 5,
              pointRadius: 1,
              pointHoverRadius: 10,
              borderColor: gradientStroke,
              pointBorderColor: gradientStroke,
              pointBackgroundColor: gradientStroke,
              pointHoverBackgroundColor: gradientStroke,
              pointHoverBorderColor: gradientStroke
            }
          ]
        },
        options: {
          responsive: true,
          tooltips: {
            bodySpacing: '10',
            backgroundColor: mainColor,
            borderColor: mainColor,
            titleFontSize: 18,
            bodyFontSize: 18,
            borderWidth: '0',
            titleFontColor: '#fff',
            bodyFontColor: '#fff',
            cornerRadius: 10,
            displayColors: false,
            position: 'average',
            intersect: false,
            callbacks: {
              label: (tooltipItem, data) => {
                return NumberHelper.currency(tooltipItem.yLabel, true);
              }
            }
          },
          legend: {
            position: 'bottom',
            display: false
          },
          hover: {
            mode: 'index'
          },
          scales: {
            xAxes: [
              {
                display: true,
                fontColor: '#666',
                scaleLabel: {
                  display: false
                },
                gridLines: {
                  // color: 'rgba(255, 255, 255, 0.2)',
                  display: false
                },
                ticks: {
                  fontSize: 16,
                  fontColor: '#C4C4C4'
                }
              }
            ],
            yAxes: [
              {
                // display: false,
                stacked: true,
                id: 'CustomersCountAxis',
                position: 'left',
                ticks: {
                  callback: value => {
                    return NumberHelper.currency(value, true);
                  },
                  beginAtZero: true,
                  fontSize: 16,
                  fontColor: '#C4C4C4',
                  min: 0,
                  precision: 0
                },
                gridLines: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.05)'
                }
              }
            ]
          }
        }
      };

      if (window.myLine) {
        window.myLine.destroy();
      }
      window.myLine = new Chart(ctx, config);
    } catch (e) {}
  }

  _changeType(e) {
    this.setState(
      {
        type: e.target.value
      },
      () => {
        this.refreshTransactions();
      }
    );
  }

  _onFilterChange(e) {
    e.persist();
    this.setState({ termTemp: e.currentTarget.value }, () =>
      this.onChangeDelayed()
    );
  }

  _clearFilters() {
    this.setState(
      {
        startDate: null,
        endDate: null,
        term: '',
        termTemp: ''
      },
      () => {
        this.refreshTransactions();
      }
    );
  }

  changeModel() {
    switch (this.state.type) {
      case 'all':
        return TransactionModel;
      case 'category':
        return TransactionCategoriesModel;
      case 'merchant':
        return TransactionMerchantsModel;
      default:
        return TransactionModel;
    }
  }

  _getClearFiltersButton() {
    // if (!this.state.startDate && !this.state.endDate && !this.state.term.length) {
    //   return null;
    // }
    return (
      <AppButton
        customstyle={{ maxWidth: 200 }}
        onClick={() => this._clearFilters()}
      >
        <Trans>Clear filters</Trans>
      </AppButton>
    );
  }

  async getExportData() {
    const result = await Api.call('transactions/export');

    if (result.success) {
      window.location = result.data.download;
      // return result.data;
    }

    return null;
  }

  render() {
    const { profile, accounts } = this.props;
    const { type } = this.state;
    // let card = CardService.getDefaultCard(cards);
    const model = this.changeModel();

    const account = AccountService.getMainAccount(accounts);
    if (!account) return null;

    // let paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    // if (!paymentDevice) return null;

    return (
      <div className="appContainer">
        <DashboardHeader />
        <div className="container">
          <div className="profile-container">
            <div className="row">
              <div className="col-lg-2 stat-holder">
                <h3>
                  <Trans>Statistic</Trans>
                </h3>
                <div style={{ marginTop: 60, marginBottom: 20 }}>
                  <p>
                    <Trans>Total available</Trans>
                  </p>
                  <span>
                    {NumberHelper.currency(account.balance, account.currency)}
                  </span>
                </div>
                <div>
                  <p>
                    <Trans>Spent Today</Trans>
                  </p>
                  <span>
                    {NumberHelper.currency(
                      account.spentToday,
                      account.currency
                    )}
                  </span>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="chart-top">
                  {/* {this.state.type == 'all' ? <StatementExport onClick={() => this.getExportData()}/> : null} */}
                  <div className="chart-buttons-wrapper">
                    {this.state.periods.map((period, index) => (
                      <span
                        key={`period${index}`}
                        onClick={() => this._changePeriod(period)}
                        className={period.active ? 'active' : ''}
                      >
                        <Trans>{period.text}</Trans>
                      </span>
                    ))}
                  </div>
                </div>
                <canvas
                  id="numberStatementsChart"
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          </div>
          <div className="profile-container" style={{ marginTop: 50 }}>
            <div className="row" style={{ width: '100%', margin: 0 }}>
              <div className="col-lg-8 statements-transaction-wrapper">
                <AppList
                  onInit={list => (this._list = list)}
                  params={{
                    limit: 6,
                    startDate: this.state.startDate
                      ? this.state.startDate.format('Y-M-D')
                      : null,
                    endDate: this.state.endDate
                      ? this.state.endDate.format('Y-M-D')
                      : null,
                    term: this.state.term
                  }}
                  model={model}
                  renderItem={(item, index) => (
                    <TransactionItem
                      key={`item${index}`}
                      transaction={item}
                      type={this.state.type}
                    />
                  )}
                  renderWrapper={children => (
                    <ul className="transactions-list" style={{ padding: 0 }}>
                      {children}
                    </ul>
                  )}
                  renderShowMore={(data, cb) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative'
                        }}
                      >
                        <div className="last-trans-overlay" />
                        <AppButton onClick={() => cb()}>
                          <Trans>Load more transactions</Trans>
                        </AppButton>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="col-lg-4 filter-container">
                <h3>
                  <Trans>Show transactions</Trans>
                </h3>
                <div className="btn-holder">
                  <span
                    className={type === 'all' ? 'active' : ''}
                    onClick={() =>
                      this.setState({ type: 'all' }, () => {
                        this.refreshTransactions();
                      })
                    }
                  >
                    <Trans>All</Trans>
                  </span>
                  <span
                    className={type === 'category' ? 'active' : ''}
                    onClick={() =>
                      this.setState({ type: 'category' }, () => {
                        this.refreshTransactions();
                      })
                    }
                  >
                    <Trans>Category</Trans>
                  </span>
                  <span
                    className={type === 'merchant' ? 'active' : ''}
                    onClick={() =>
                      this.setState({ type: 'merchant' }, () => {
                        this.refreshTransactions();
                      })
                    }
                  >
                    <Trans>Merchants</Trans>
                  </span>
                </div>

                <h3>
                  <Trans>By Period</Trans>
                </h3>

                <div className="datePickerHolder">
                  <p className="calendarFrom statement-date-text">
                    <Trans>From</Trans>
                  </p>
                  <DatePicker
                    selected={Date.parse(this.state.startDate)}
                    onChange={this.handleChangeFrom}
                    className="dateFrom statement-date"
                    dateFormat="dd/MM/yyyy"
                    // locale="en-gb"
                    isClearable={!!this.state.startDate}
                  />
                </div>

                <div className="datePickerHolder">
                  <p className="calendarTo statement-date-text">
                    <Trans>To</Trans>
                  </p>
                  <DatePicker
                    selected={Date.parse(this.state.endDate)}
                    onChange={this.handleChangeTo}
                    className="dateTo statement-date"
                    dateFormat="dd/MM/yyyy"
                    // locale="en-gb"
                    isClearable={!!this.state.endDate}
                  />
                </div>

                <h3>
                  <Trans>By merchant name</Trans>
                </h3>

                <div className="search-wrapper">
                  <i className="fa fa-search" />
                  <input
                    value={this.state.termTemp}
                    type="text"
                    className="search-by-name"
                    placeholder="e.g. McDonalds"
                    onChange={text => this._onFilterChange(text)}
                  />
                </div>
                <div className="filter-button-wrapper">
                  {/* <button className="orange-button small-button">Apply filters</button> */}
                  {this._getClearFiltersButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    paymentDevices: state.paymentDevices,
    accounts: state.accounts
  };
};

export default connect(mapStateToProps)(StatementsPage);
