import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormSubmitWrapper, FormHandler, InputField } from '@pdl/app';
import { MEMBER_TOKEN_PRELOAD } from '@pdl/app/modules/auth/AuthActions';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import AuthService from '@pdl/app/logic/services/AuthService';
import ChangePasswordFormModel from '../models/ChangePasswordFormModel';
import LoginDataModel from '../models/LoginDataModel';
import i18n from 'i18n';
import { Trans } from 'react-i18next';

export class SecuritySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePass: true
    };
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ChangePasswordFormModel({
        oldPassword: '',
        password: '',
        repeatedPassword: ''
      }),
      {
        loginData: [],
        loading: false
      }
    );
  }

  getAllLogData() {
    const items = [];
    if (this.state.loginData !== 0) {
      this.state.loginData.forEach((i, index) => {
        let os = <span className="status-os">{i.deviceOs}</span>;
        if (!i.deviceOs && i.isWeb) {
          os = <span className="status-os"> Web</span>;
        }
        let active = (
          <span className="status-active">
            <Trans>Status: </Trans>
          </span>
        );
        if (i.isActive == true) {
          active = (
            <span className="status-active" style={{ color: '#9B9B9B' }}>
              <Trans>Status: </Trans>
              <span style={{ color: '#1BD800' }}>
                <Trans>Active now</Trans>
              </span>
            </span>
          );
        }
        items.push(
          <div className="logDataRow" key={`item${index}`}>
            <div className="status-wrapper">
              {/* <span>{i.user.fullName}</span> */}
              {/* <span>{i.user.email}</span> */}
              {os}
              {/* <i className="fa fa-circle" style={{ color: '#9B9B9B', fontSize: 8, marginRight: 20, marginLeft: -30, position: 'relative', top: -5 }}></i> */}
              {active}
              {/* <span><span style={{ marginRight: 10, color: '#9B9B9B' }}>Last IP used:</span> {i.lastUsedIp}</span> */}
              {/* <span>{DateHelper.date(i.modified)}</span> */}
              {/* <span>{DateHelper.date(i.tokenExpiryDate)}</span> */}
              {/* <span>{i.time}</span> */}
              {/* <span>{i.logOut === '' ? null : <button className='redButton' style={{ padding: '5px 40px' }}>{i.logOut}</button>}</span> */}
            </div>
            <div>
              <span>
                {i.logOut === '' ? null : (
                  <button
                    className=""
                    style={{
                      backgroundColor: 'white',
                      border: '2px solid #ad8a4e',
                      color: '#ad8a4e',
                      minWidth: 100
                    }}
                    onClick={() => this._removeDevice(i.id)}
                  >
                    <Trans>Logout</Trans>
                  </button>
                )}
              </span>
            </div>
          </div>
        );
      });
    }
    return items;
  }

  changePassword() {
    const hidden = document.getElementById('hidden');
    const button = document.getElementById('editButton');
    hidden.classList.toggle('open');

    if (hidden.classList.contains('open')) {
      document.getElementById('editButton').textContent = 'CLOSE';
      this.setState({
        changePass: true
      });
    } else {
      document.getElementById('editButton').textContent = 'EDIT';
      this.setState({
        changePass: false
      });
    }
  }

  onSubmitSuccess(data) {
    if (data.success) {
      LoadingHelper.success('You have successfully changed your password.');
      this.setState({
        record: {
          ...this.state.record,
          oldPassword: '',
          password: '',
          repeatedPassword: ''
        }
      });
    }
  }

  componentDidMount() {
    this._loadData();
  }

  async _loadData(silent = false) {
    if (this.state.loading) return;

    if (!silent) {
      this.setState({
        loading: true
      });
    }
    const loginData = await new LoginDataModel().getLoginData();
    try {
      this.setState({
        loading: false,
        loginData
      });
    } catch (e) {
      console.log(e);
    }
  }

  _removeAll() {
    LoadingHelper.executeAction(
      () => {
        return new LoginDataModel().logOutAllSessions();
      },
      {
        successMessage: 'Success!',
        loadingMessage: 'Removing all sessions',
        failureMessage: 'An error ocurred, please try again later',
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  _removeDevice(id) {
    LoadingHelper.executeAction(
      () => {
        return new LoginDataModel().logOutDevice(id);
      },
      {
        successMessage: 'Success!',
        loadingMessage: 'Removing session',
        failureMessage: 'An error ocurred, please try again later',
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  render() {
    const logData = this.getAllLogData();
    const { formHandler } = this;
    return (
      <div className="container">
        <div
          className="row account-wrapper settings-account-wrapper"
          style={{ marginTop: 0 }}
        >
          <div
            className="row"
            style={{
              width: '100%',
              padding: '0px 0px 30px 0px',
              margin: 0,
              position: 'relative'
            }}
          >
            <div style={{ width: '100%' }}>
              <div className="change-passsword-form" id="">
                <FormSubmitWrapper
                  changeSubmitButtonWrapper="change-submit-btn-wrapper"
                  buttonContainer="custom-form-btn-container"
                  buttonClass="gold-button small-button button-right-account"
                  formHandler={formHandler}
                  buttonText={i18n.t('Save')}
                >
                  <div className="login-input-wrapper">
                    <InputField
                      className="input-group"
                      materialProps={{
                        fullWidth: true
                      }}
                      type="password"
                      label={i18n.t('Current password *')}
                      name="oldPassword"
                      value={this.state.record.oldPassword}
                      handler={formHandler}
                    />
                  </div>
                  <div className="login-input-wrapper">
                    <InputField
                      className="input-group"
                      materialProps={{
                        fullWidth: true
                      }}
                      type="password"
                      label={i18n.t('New password *')}
                      name="password"
                      value={this.state.record.password}
                      handler={formHandler}
                    />
                  </div>
                  <div className="login-input-wrapper">
                    <InputField
                      className="input-group"
                      materialProps={{
                        fullWidth: true
                      }}
                      type="password"
                      label={i18n.t('Repeated new password *')}
                      name="repeatedPassword"
                      value={this.state.record.repeatedPassword}
                      handler={formHandler}
                    />
                  </div>
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
          {/* <div className="pageHeader" style={{ marginTop: 50 }}><h3 className="pageTitle">Login Data</h3></div> */}
          <div style={{ width: '100%', position: 'relative' }}>
            {/* <p style={{ color: '#201D5B', position: 'absolute', top: 0 }}>Login data</p> */}
            <h4 className="account-menu-title">
              <Trans>Login Sessions</Trans>
              <i className="fa fa-caret-down" />
            </h4>
            <div
              className="row"
              style={{
                padding: '0px 0 30px 0',
                width: '100%',
                margin: 0,
                position: 'relative'
              }}
            >
              <div className="card">
                {/* <div className='logDataRow'>
                <span>Device OS</span>
                <span>Last IP used</span>
                <span>Last Login</span>
                <span>Session Expires</span>
                <span>Action</span>
              </div> */}
                {logData}
              </div>
              <div>
                <button
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    marginTop: 20,
                    background: 'transparent',
                    boxShadow: 'none',
                    color: '#201D5B',
                    border: 'none'
                  }}
                  onClick={() => this._removeAll()}
                >
                  <Trans>Log out of all sessions</Trans>
                </button>
              </div>
            </div>
          </div>
          {/* <button className="gold-button" onClick={() => this.formHandler.submit()}>save</button> */}
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateToken: token => {
      dispatch({ type: MEMBER_TOKEN_PRELOAD, payload: token });
    }
  };
};

export default connect(mapDispatchToProps)(SecuritySettings);
