import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormSubmitWrapper, FormHandler, InputField } from '@pdl/app';
import TriggerEmailVerificationModel from '@pdl/app/modules/auth/models/TriggerEmailVerificationModel';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import ToastHelper from '@pdl/app/util/ToastHelper';

export default class SignUpPage extends Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new TriggerEmailVerificationModel({
        email: ' '
      }),
      {
        redirect: false
      }
    );

    this.formHandler.on('submitSuccess', data => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 500);
      }
    });

    this.formHandler.on('submitError', data => {
      if (!data.success) {
        ToastHelper.show(data.message);
      }
    });
  }

  componentDidMount() {
    const previousRecord = { ...this.state.record };
    this.setState({
      record: {
        ...previousRecord,
        email: '',
        remember: false
      }
    });
  }

  onSubmitSuccess(data) {
    // if (data.success && data.data.token) {
    //   this.props.userLoggedIn(data.data.token, data.data.account);
    // }
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.errors[0].message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/sent-link" />;
    }
    const { formHandler } = this;
    return (
      <div>
        <div className="verification-image-signup" />
        <div className="signUp-wrapper">
          <Link
            to="/login"
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontSize: 14,
              textAlign: 'center'
            }}
          >
            <Trans>Already have an account? Sign in</Trans>
          </Link>
        </div>
        <div className="container login-container signup-account-container">
          <img
            src={require('@pdl/app/assets/img/pdl-logo.svg')}
            className="login-logo"
            style={{ maxWidth: 200 }}
            alt="logo"
          />
          <div className="row">
            <div className="col-md login-holder">
              <h4
                className="login-title "
                style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
              >
                <Trans>Create your account</Trans>
              </h4>
              <div className="login-box" id="log-in">
                <div style={{ marginTop: 10 }}>
                  {/* <p style={{marginBottom: 20}}>Don’t worry. We’ll reset your password and<br />send you a link to create a new one. </p>  */}
                  <FormSubmitWrapper
                    formHandler={formHandler}
                    buttonClass="gold-button"
                    to="/sent-link"
                    buttonText={i18n.t('Next')}
                    click={() => this.setState({ redirect: true })}
                    getModalMessage={data => this._getModalMessage(data)}
                  >
                    <div className="login-input-wrapper">
                      <InputField
                        className="input-group"
                        materialProps={{
                          fullWidth: true
                        }}
                        autoComplete={this.state.record.email}
                        label={i18n.t('Email address')}
                        type="email"
                        name="email"
                        // placeholder={'Email address'}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={this.state.record.email}
                        handler={formHandler}
                      />
                    </div>
                  </FormSubmitWrapper>
                </div>
              </div>
            </div>
          </div>
          <div style={{ clear: 'both' }} />
        </div>
      </div>
    );
  }
}
