import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AppFooter: React.FC<any> = () => {
  return (
    <div className="app-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-column">
            <h3>PREPAID DELUXE</h3>
            {/* <Link to="/" className="footer-link">
              Benefits for you
            </Link> */}
            <Link to="/terms-conditions" className="footer-link">
              <Trans>Terms & Conditions</Trans>
            </Link>
            <Link to="/privacy-policy" className="footer-link">
              <Trans>Privacy Policy</Trans>
            </Link>
          </div>
          {/* <div className="col-md-4 footer-column">
            <h3>INFORMATION</h3>
            <Link to="/" className="footer-link">
              Help centre
            </Link>
            <Link to="/" className="footer-link">
              Language
            </Link>
            <Link to="/" className="footer-link">
              Resources
            </Link>
          </div> */}
          <div className="col-md-4 footer-column">
            <h3>
              <Trans>CONTACT</Trans>
            </h3>
            <Link to="/customer-care" className="footer-link">
              <Trans>Customer care</Trans>
            </Link>
            {/* <a className="footer-link" href="mailto:support@PDL.org.uk">
              Privacy Notice
            </a>
            <a className="footer-link" href="tel:0844 484 3586">
              PDL for business
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
