import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginTriggerAction } from '@pdl/app/modules/auth/AuthActions';
import { AppButton } from '@pdl/app/components';
import Auth from '@pdl/app/logic/services/AuthService';
import { Trans } from 'react-i18next';

export default class Finish extends Component {
  render() {
    return (
      <div>
        <div className="verification-image-signup" />
        <div className="signUp-wrapper">
          <Link
            to="javascript:void(0)"
            onClick={() => Auth.logout()}
            style={{
              color: '#fff',
              textDecoration: 'none',
              fontSize: 14,
              textAlign: 'center'
            }}
          >
            <Trans>Already have an account? Sign in</Trans>
          </Link>
        </div>
        <div className="container login-container signup-account-container">
          <img
            src={require('@pdl/app/assets/img/pdl-logo.svg')}
            className="login-logo"
            style={{ maxWidth: 200 }}
          />
          <div className="row">
            <div className="col-md login-holder">
              <img
                src={require('@pdl/app/assets/img/fingerprint.png')}
                className="kyc-fingerprint-icon"
              />
              <h4
                className="login-title "
                style={{
                  color: '#000',
                  textAlign: 'center',
                  fontSize: 50,
                  marginBottom: 0
                }}
              >
                KYC in progress
              </h4>
              <p
                style={{
                  marginBottom: 30,
                  marginTop: 30,
                  fontSize: 18,
                  textAlign: 'center'
                }}
              >
                We will notify you as soon as your checks are complete
              </p>
              {/* <Link
                to="/"
                className="gold-button"
                style={{ maxWidth: 400, width: '100%', margin: 'auto' }}
              >
                ok
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
