import React from 'react';
import { AppIcon } from '@pdl/app';

export const AppCard: React.FC<any> = props => {
  return (
    <div className="app-widget">
      <div className="card-header">
        <AppIcon
          name={`fa.${props.name}`}
          style={{ fontSize: 25, color: '#ad8a4e' }}
        />
        <p>{props.title}</p>
      </div>
      <div className="card-content">{props.children}</div>
    </div>
  );
};

export default AppCard;
