import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppIcon, AppButton } from '@pdl/app';
import ModalService from '@pdl/app/logic/services/ModalService';
import VerificationService from '@pdl/app/logic/services/VerificationService';
import ToastHelper from '@pdl/app/util/ToastHelper';
import CardService from '../CardService';
import CardDetails from '../components/CardDetails';
import LockCard from '../components/LockCard';
import ShowPin from '../components/ShowPin';
import { Trans } from 'react-i18next';

class CardPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: null
    };
  }

  changeContent() {
    const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);

    switch (this.state.type) {
      case 'edit':
        return <CardDetails card={paymentDevice} />;
      case 'lock':
        return <LockCard card={paymentDevice} />;
      case 'pin':
        return <ShowPin card={paymentDevice} />;
      default:
    }
  }

  _getNoCardElement() {
    const verified = VerificationService.isVerified(this.props.profile);

    return (
      <div className="appContainer">
        <div className="container">
          <div className="card-wrapper no-cards">
            {verified ? (
              <Link to="/order-card">
                <div className="add-new-card">
                  <p>
                    <Trans>+ Add new card</Trans>
                  </p>
                </div>
              </Link>
            ) : null}
            <h3 style={{ marginTop: 20, fontWeight: 'bold' }}>
              <Trans>You don't have any card linked to your account</Trans>
            </h3>
            <p style={{ marginTop: 20 }}>
              <Trans>
                {verified
                  ? 'Add new card and enjoy all the benefits of Prepaid Deluxe'
                  : 'You will be able to purchase cards once your account is verified'}
              </Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const paymentDevice = CardService.getDefaultCard(this.props.paymentDevices);
    if (!paymentDevice) return this._getNoCardElement();

    const verified = VerificationService.isVerified(this.props.profile);

    const cardActive = paymentDevice.isLive;
    // let cardActive = false;

    const loadAccount = (
      <div
        onClick={() =>
          verified
            ? this.props.history.push('/load-card')
            : ToastHelper.show(
                'Your account is not verified yet. Please stay tuned.'
              )
        }
        className="send-load-btn-wrapper"
      >
        {' '}
        <div className="send-load-icon-wrapper">
          {' '}
          <AppIcon
            name="fa.plus"
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        <Trans>Load</Trans>
      </div>
    );
    const sendMoney = (
      <div
        onClick={() =>
          verified
            ? this.props.history.push('/send-money')
            : ToastHelper.show(
                'Your account is not verified yet. Please stay tuned.'
              )
        }
        className="send-load-btn-wrapper"
      >
        {' '}
        <div className="send-load-icon-wrapper">
          <AppIcon
            name="fa.long-arrow-right"
            style={{ fontSize: 24, color: cardActive ? 'white' : 'black' }}
          />{' '}
        </div>
        <Trans>Send money</Trans>
      </div>
    );

    const { type } = this.state;

    let activeCardBtn = null as any;
    let activeCardOverlay = null as any;

    const maskedPan = paymentDevice.pan.replace(/.(?=.{4})/g, '*');
    const splitPan = [...maskedPan]
      .map((d, i) => (i % 4 === 0 ? ` ${d}` : d))
      .join('')
      .trim();

    let panNumber = <span className="pan">{splitPan}</span>;

    let cardHolderName = (
      <span className="card-holder-name">{paymentDevice.userFullName}</span>
    );
    let exp = <span className="exp">{paymentDevice.expires}</span>;

    if (!cardActive) {
      activeCardBtn = (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
          }}
        >
          <AppButton
            onClick={() =>
              ModalService.showModal('ACTIVATE_CARD', { paymentDevice })
            }
          >
            <Trans>ACTIVATE CARD</Trans>
          </AppButton>
        </div>
      );
      activeCardOverlay = (
        <div className="card-not-active-overlay">
          <h3>
            <Trans>ACTIVATE CARD</Trans>
          </h3>
        </div>
      );

      panNumber = null as any;
      cardHolderName = null as any;
      exp = null as any;
    }

    // if (paymentDevice.locked) {
    //     activeCardOverlay = (
    //         <div className='card-not-active-overlay'>
    //             <AppIcon name={'fa.lock'} style={{ fontSize: 100, color: 'white' }} />
    //         </div>
    //     )
    // }

    return (
      <div className="appContainer">
        <div className="container">
          <div className="card-wrapper">
            <div className="card-navigation-wrapper">
              <div style={{ position: 'relative' }}>
                {activeCardOverlay}
                {panNumber}
                {exp}
                <img
                  src={require('@pdl/app/assets/img/pdl-card.png')}
                  className="card-page-card-image"
                  alt="card"
                />
                {/* {cardHolderName} */}
              </div>
              <div className="card-page-card-navigation">
                <ul className={!cardActive ? 'not-active' : ''}>
                  <li
                    className={type === 'edit' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'edit' })}
                  >
                    <AppIcon name="fa.pencil" style={{ fontSize: 24 }} />
                  </li>
                  <li
                    className={type === 'lock' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'lock' })}
                  >
                    <AppIcon name="fa.key" style={{ fontSize: 24 }} />
                  </li>
                  <li
                    className={type === 'pin' ? 'active' : ' '}
                    onClick={() => this.setState({ type: 'pin' })}
                  >
                    <AppIcon name="fa.ellipsis-h" style={{ fontSize: 24 }} />
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="send-load-btn-holder"
              style={{
                opacity: cardActive ? 1 : 0.2,
                pointerEvents: cardActive ? 'auto' : 'none'
              }}
            >
              {loadAccount}
              {sendMoney}
            </div>
            {/* <div
              style={{
                fontSize: 12,
                color: 'rgba(0,0,0,0.5)',
                margin: '50px 0 0 0'
              }}
            >
              Your Mastercard is now welcomed at millions of locations
              worldwide.
            </div> */}
            <div style={{ maxWidth: 600, width: '100%', marginTop: 50 }}>
              {this.changeContent()}
              {activeCardBtn}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};
export default connect(mapStateToProps)(CardPage);
