import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { getLocaleCountry } from '../LocaleUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import theme from '../../../../styles/styles';
import LocalizationService from '../LocalizationService';

interface IProps {
  listBackgroundColor?: string;
  listBorderStyle?: string;
  currentLanguageTextColor?: string;
  languageListTextColor?: string;
}

const LanguageSwitcher: React.FC<IProps> = ({
  listBackgroundColor,
  listBorderStyle,
  currentLanguageTextColor,
  languageListTextColor
}) => {
  const localization = useSelector((state: any) => state.localization);
  const [currentLanguage, setCurrentLanguage] = useState(
    localization.languages[0]
  );
  const [isListOpen, setListOpen] = useState(false);

  useEffect(() => {
    const { languages } = localization;
    const curLang = languages.find(x => x.locale === localization.locale);
    if (curLang) {
      setCurrentLanguage(curLang);
    }
  }, [localization]);

  function toggleList() {
    setListOpen(!isListOpen);
  }

  function _changeLanguage(language: { locale: any }) {
    LocalizationService.setLocale(language.locale);
    toggleList();
  }

  if (!currentLanguage) return null;

  const otherLanguages = [] as any;
  const currentLocale = currentLanguage.locale || 'en';
  let otherLanguagesCounter = 0;

  if (localization) {
    localization.languages.forEach(language => {
      if (language.locale !== currentLocale) {
        otherLanguages.push(
          <div
            onClick={() => _changeLanguage(language)}
            className="language"
            style={{
              marginTop: otherLanguagesCounter !== 0 ? 5 : 0
            }}
            key={`lang${language.id}`}
          >
            <div className="language-content">
              <span
                className="language-content-text"
                style={{ color: languageListTextColor }}
              >
                {language.name}
              </span>{' '}
              <img
                src={getLocaleCountry(language.locale)}
                alt={`Language ${language.name}`}
                width={25}
              />
            </div>
          </div>
        );
        otherLanguagesCounter++;
      }
    });
  }

  return (
    <div className="language-bar">
      <div className="current-language" onClick={toggleList}>
        <div className="current-language-content">
          <span
            className="current-language-content-text"
            style={{ color: currentLanguageTextColor }}
          >
            {currentLanguage.name}
          </span>{' '}
          <img
            src={getLocaleCountry(currentLanguage.locale)}
            alt={`Language ${currentLanguage.name}`}
            width={25}
          />
        </div>
        {isListOpen ? (
          <ExpandLessIcon style={{ color: 'black' }} />
        ) : (
          <ExpandMoreIcon style={{ color: 'black' }} />
        )}
      </div>
      {isListOpen && (
        <div
          className="language-list"
          style={{
            backgroundColor: listBackgroundColor || 'transparent',
            border: listBorderStyle || 'none'
          }}
        >
          {otherLanguages}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
