import URL from 'url-parse';

const Config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  url: process.env.REACT_APP_URL || ''
};

const env =
  Config.env ||
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'dev'
    : 'live');

const publicUrl = new URL(Config.url).origin;

const common = {
  resetDB: false,
  disablePasscode: false,
  forceIntros: false,
  publicUrl,
  passcodeLength: 6,
  cardRatio: 1.56,
  physicalCardCost: 7.5,
  maxTopUpValue: 25000,
  minTopUpValue: 300
};

const configuration = {
  ...common,
  checkout_url:
    process.env.REACT_APP_CHECKOUT_URL ||
    'https://api.sandbox.checkout.com/tokens',
  checkout_public_key:
    process.env.REACT_APP_CHECKOUT_PUBLIC_KEY ||
    'pk_test_2f4d646b-e715-4496-9ccd-ff839168dc14',
  ...Config
};

const config = {
  env,

  // Preloaded configuration
  ...configuration,

  support: {
    phone: '+46 (0) 20105051',
    email: 'support@ppdl.eu'
  },

  cards: {
    cardPrice: {
      GBP: 10,
      SEK: 150
    }
  },
  topUp: {
    min: 300,
    max: 25000
  },
  sendMoney: {
    min: 300,
    max: 25000
  },

  facebook: {},
  twitter: {},
  linkedin: {},

  /**
   * Merge data with current object
   *
   * @param {Object} result
   */
  async merge(result) {
    Object.assign(this, result);
  }
};

export default config;
