import React from 'react';
import Config from '@pdl/app/config';
import { Trans } from 'react-i18next';

export default class SupportContacts extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      items: [
        {
          name: 'Support',
          phone: Config.support.phone,
          email: Config.support.email
        }
        // {
        //   name: 'Carina Andersson',
        //   phone: '+46 (0)72-4011184',
        //   email: 'carina@ppdl.eu'
        // },
        // {
        //   name: 'Pauline Klintebring',
        //   phone: '+46 (0)72-4011183',
        //   email: 'pauline@ppdl.eu'
        // },
        // {
        //   name: 'Harry Klintebring',
        //   phone: '+46 (0)72-0923687',
        //   email: 'harry@ppdl.eu'
        // },
        // {
        //   name: 'Annette Klintebring',
        //   phone: '',
        //   email: 'annette@ppdl.eu'
        // },
        // {
        //   name: 'Anders Lannerström',
        //   phone: '+46 (0)72-0929088',
        //   email: 'anders@ppdl.eu'
        // }
      ]
    };
  }

  _getItems() {
    const elements: any[] = [];

    this.state.items.forEach((item, index) => {
      elements.push(
        <div
          key={`item${index}`}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            // borderBottom: '1px solid gray',
            maxWidth: 500,
            margin: 'auto',
            paddingTop: 10
          }}
        >
          {/* <h3>{item.name}</h3> */}
          <p style={{ padding: '20px 0 10px 0' }}>
            <Trans>Phone: </Trans>
            <a href={`tel:${item.phone}`} style={{ color: '#ad8a4e' }}>
              {item.phone}
            </a>
          </p>
          <p style={{ padding: '0 0 10px 0' }}>
            <Trans>Email: </Trans>
            <a href={`mailto:${item.email}`} style={{ color: '#ad8a4e' }}>
              {item.email}
            </a>
          </p>
        </div>
      );
    });
    return elements;
  }

  render() {
    return this._getItems();
  }
}
