import React, { Component } from 'react';
import Chart from 'chart.js';
import $ from 'jquery';

export default class WalletChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    }
  }
  componentDidMount() {
    this.renderStatementsChart2();
  }
  renderStatementsChart2() {
    try {
      let ctx2 = document.getElementById("numberStatementsChart2").getContext("2d");
      let gradient = ctx2.createLinearGradient(0, 0, 0, ctx2.canvas.height);
      gradient.addColorStop(0, 'rgba(56, 56, 56, 0.2)');
      gradient.addColorStop(0.5, 'rgba(56, 56, 56, 0.1)');
      gradient.addColorStop(0.8, 'rgba(56, 56, 56, 0)');
      let config2 = {
        type: 'line',
        data: {
          labels: this.state.labels,
          datasets: [
            {
              yAxisID: 'CustomersCountAxis',
              // data: this.state.data,
              data: [15, 25, 5, 30, 18, 25, 3],
              borderColor: '#ad8a4e',
              // backgroundColor: '#ad8a4e',
              backgroundColor: gradient,
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: 0,
              pointBackgroundColor: 'rgba(255, 255, 255, 0)',
            }]
        },
        options: {
          responsive: true,
          tooltips: {
            enabled: false,
            bodySpacing: '10',
            backgroundColor: '#fff',
            borderColor: 'rgba(173, 138, 78, 0)',
            borderWidth: '1',
            titleFontColor: '#F9F9F9`',
            bodyFontColor: '#F9F9F9`',
            cornerRadius: 0,
            displayColors: false,
            position: 'average',
            intersect: false
          },
          legend: {
            position: 'bottom',
            display: false
          },
          hover: {
            mode: 'index'
          },
          scales: {
            xAxes: [{
              display: false,
              fontColor: '#666',
              scaleLabel: {
                display: false,
              },
              gridLines: {
                color: 'rgba(255, 255, 255, 0)',
                // display: false,
              },
              ticks: {
                fontColor: 'rgba(255, 255, 255, 0)',
                display: false
              }
            }],
            yAxes: [
              {
                display: false,
                stacked: true,
                id: 'CustomersCountAxis',
                position: 'left',
                ticks: {
                  beginAtZero: true,
                  fontColor: 'rgba(255, 255, 255, 0)',
                  min: 0,
                  // precision: 0,
                },
                gridLines: {
                  display: false,
                  color: 'rgba(255, 255, 255, 0)',
                }
              }]
          }
        }
      };

      if (window.myLine2) {
        window.myLine2.destroy();
      }
      window.myLine2 = new Chart(ctx2, config2);
    } catch (e) {

    }
  }

  render() {
    return (
      <div className="top-chart-container">
        <canvas id="numberStatementsChart2" style={{ maxWidth: '100%' }}></canvas>
      </div>
    )
  }
}

