import React from 'react';
import DateHelper from '@pdl/app/util/DateHelper';
import ModalDialog from '@pdl/app/components/modal/ModalDialog';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Api from '@pdl/app/logic/api/Api';
import AuthService from '@pdl/app/logic/services/AuthService';
import RoutingService from '@pdl/app/logic/services/RoutingService';
import ProfileImage from '@pdl/app/components/images/ProfileImage';
import { AppIcon, AppButton } from '@pdl/app';
import Config from '../../../config';
import AccountModel from '../models/AccountModel';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const circleStyle = {
  // position: 'absolute',
  // left: '50%',
  // marginLeft: -25,
  color: '#EA9600'
};

export default class AccountInfoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditPictureModal: false,
      image: props.profile.imagePath
        ? Config.publicUrl + props.profile.imagePath
        : require('@pdl/app/assets/profile-image-dummy.png'),
      borderRadius: 400,
      width: 400,
      height: 400,
      loadingImage: false,
      uploadingImage: false
    };
  }

  openModal() {
    this.setState({
      showEditPictureModal: true
    });
  }

  onClose() {
    this.setState({
      showEditPictureModal: false
    });
  }

  /**
   * Check if path is active
   *
   * @param item
   * @returns {boolean}
   */
  isPathActive(item) {
    // Get current path from context
    const currentPath = this.props.location.pathname;

    // If paths same?
    if (currentPath === item) {
      return true;
    }

    // Default false
    return false;
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] });
  };

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value);
    this.setState({ borderRadius });
  };

  handleWidth = e => {
    const width = parseInt(e.target.value);
    this.setState({ width });
  };

  handleHeight = e => {
    const height = parseInt(e.target.value);
    this.setState({ height });
  };

  onClickSave = () => {
    if (this.editor) {
      this.setState(
        {
          loadingImage: true
        },
        () => {
          const canvas = this.editor.getImage().toDataURL();
          this._imageSelected(canvas);
          this.onClose();
        }
      );
    }
  };

  setEditorRef = editor => (this.editor = editor);

  _imageSelected(image) {
    this.setState({
      loadingImage: true
    });
    Api.call(
      'auth/photo-update',
      {
        image
      },
      progressEvent => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      }
    )
      .then(response => {
        AuthService.refreshAuth();
        RoutingService.preventScroll();
      })
      .catch(() => {})
      .finally(() => {
        this.setState({
          loadingImage: false
        });
      });
  }

  render() {
    const { profile } = this.props;

    let profileImg = null;
    profileImg = <ProfileImage profile={profile} />;
    const isFullyVerified = AccountModel.isFullyVerified(profile);
    return (
      <div>
        <div className="header personal-info-header">
          {/* <img src={require('WebsiteApp/assets/img/profile-dummy.png')} alt="" /> */}
          <div className="position-relative">
            <div className="profilePicture">
              {this.state.loadingImage ? (
                <div className="loadingHolderEditPicture">
                  <CircularProgress style={circleStyle} size={50} />
                </div>
              ) : null}
              {profileImg}
            </div>
            {this.props.editImage ? (
              <AppButton
                onClick={this.openModal.bind(this)}
                className="change-image-button"
              >
                <Trans>Change image</Trans>
              </AppButton>
            ) : null}
          </div>
          <div className="d-flex flex-column align-items-start justify-content-start">
            <p className="account-owner">
              <Trans>Account Owner</Trans>
            </p>
            <p className="profile-full-name">{profile.fullName}</p>
            <p className="last-login">
              <Trans>Customer Reference: </Trans>
              <span>{profile.customerReference}</span>
            </p>
            <p className="last-login mb-3">
              <Trans>LAST LOGIN: </Trans>
              <span>{DateHelper.date(profile.lastLogin)}</span>
            </p>

            <Link className="gold-button small-button" to="/edit-personal-data">
              <Trans>Edit profile</Trans>
            </Link>
          </div>
        </div>
        <div className="info">
          <h4>
            <Trans>Basic info</Trans> <i className="fa fa-caret-down" />
          </h4>
          <div>
            {/* <p>
              <Trans>Title: </Trans>
              <span>{profile.title || '-'}</span>
            </p> */}
            <p>
              <Trans>First name: </Trans>
              <span>{profile.firstName || '-'}</span>
            </p>
            {/* <p>
              Middle name: <span>{profile.middleName || '-'}</span>
            </p> */}
            <p>
              <Trans>Last name: </Trans>
              <span>{profile.lastName || '-'}</span>
            </p>
            <p>
              <Trans>Date of birth: </Trans>
              <span>{DateHelper.date(profile.birthDate)}</span>
            </p>
            <p>
              <Trans>National insurance number: </Trans>
              <span>{profile.insuranceNumber || '-'}</span>
            </p>
          </div>

          <h4>
            <Trans>Contact information</Trans>
            <i className="fa fa-caret-down" />
          </h4>
          <div>
            <p>
              <Trans>Email: </Trans>
              <span>{profile.email || '-'}</span>
            </p>
            <p>
              <Trans>Mobile no: </Trans>
              <span>{profile.mobile || '-'}</span>
            </p>
          </div>
          <h4>
            <Trans>Address</Trans>
            <i className="fa fa-caret-down" />
          </h4>
          <div>
            <p>
              <Trans>Street: </Trans>
              <span>{profile.addressOne || '-'}</span>
            </p>
            <p>
              <Trans>City/town: </Trans>
              <span>{profile.city || '-'}</span>
            </p>
            <p>
              <Trans>Postal code: </Trans>
              <span>{profile.postCode || '-'}</span>
            </p>
            <p>
              <Trans>Country: </Trans>
              <span>{profile.country.name || '-'}</span>
            </p>
          </div>
          <h4>
            <Trans>Unique Reference</Trans>
            <i className="fa fa-caret-down" />
          </h4>
          <div>
            <p>
              <Trans>Reference number: </Trans>
              <span>{profile.customerReference || '-'}</span>
            </p>
            <p className="italic">
              <Trans>
                Provide this reference to your contacts who want to send you
                money.
              </Trans>
            </p>
          </div>
        </div>
        <ModalDialog
          show={this.state.showEditPictureModal}
          onClose={this.onClose.bind(this)}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Dropzone
              onDrop={this.handleDrop}
              disableClick
              multiple={false}
              style={{ width: this.state.width, height: this.state.height }}
            >
              {() => (
                <div>
                  <AvatarEditor
                    ref={this.setEditorRef}
                    width={this.state.width}
                    height={this.state.height}
                    border={50}
                    color={[0, 0, 0, 0.8]}
                    borderRadius={
                      this.state.width / (100 / this.state.borderRadius)
                    }
                    image={this.state.image}
                    className="editor-canvas"
                  />
                </div>
              )}
            </Dropzone>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
              className="uploadButton"
            >
              <label htmlFor="profile_pic">
                <Trans>Choose image to upload:</Trans>
              </label>
              <input
                type="file"
                id="profile_pic"
                name="profile_pic"
                title=" "
                accept=".jpg, .jpeg, .png"
                onChange={this.onImageChange.bind(this)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 20
              }}
            >
              <button
                className="gold-border-button"
                onClick={this.onClose.bind(this)}
              >
                <Trans>Cancel</Trans>
              </button>
              <AppButton
                className="gold-button"
                onClick={this.onClickSave.bind(this)}
              >
                <Trans>Save</Trans>
              </AppButton>
            </div>
          </div>
        </ModalDialog>
      </div>
    );
  }
}
