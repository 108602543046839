import React from 'react';
import { AppIcon } from '@pdl/app';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import CardModel from '@pdl/app/modules/card/models/CardModel';
import AuthService from '@pdl/app/logic/services/AuthService';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

class LockCardButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  _changeCardLockStatus(status: any) {
    const { card } = this.props;
    if (!card) return null;

    LoadingHelper.executeAction(
      () => {
        return new CardModel().freezePaymentDevice(card.id, status);
      },
      {
        successMessage: status
          ? i18n.t('Your card is locked')
          : i18n.t('Your card is now unlocked'),
        loadingMessage: status
          ? i18n.t('Locking card...')
          : i18n.t('Unlocking card...'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        manualClose: true,
        renderIcon: (state: string, params: any) => {
          if (state === 'loading') return null;
          return status ? (
            <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
          ) : (
            <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  render() {
    const { card } = this.props;
    if (!card) return null;

    let lockElement = null as any;

    if (card.locked) {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(false)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon
              name="fa.unlock-alt"
              style={{ fontSize: 24, color: 'white' }}
            />
          </div>
          <Trans>Unlock card</Trans>
        </div>
      );
    } else if (card.status === 'ok') {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(true)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon name="fa.lock" style={{ fontSize: 24, color: 'white' }} />
          </div>
          <Trans>Lock card</Trans>
        </div>
      );
    }
    if (card.locked && this.props.custom) {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(false)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <AppIcon
            name="fa.unlock-alt"
            style={{ fontSize: 24, color: '#ad8a4e' }}
          />
          <Trans>Unlock card</Trans>
        </div>
      );
    } else if (card.status === 'ok' && this.props.custom) {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(true)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <AppIcon name="fa.lock" style={{ fontSize: 24, color: '#ad8a4e' }} />
          <Trans>Lock card</Trans>
        </div>
      );
    }

    return <div className="button-wrapper">{lockElement}</div>;
  }
}

export default LockCardButton;
