import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  AppButton
} from '@pdl/app';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { Redirect } from 'react-router-dom';
import OTPVerifyCodeModel from './models/OTPVerifyCodeModel';
import Api from '../../../logic/api/Api';
import { loginTriggerAction } from '../../auth/AuthActions';
import AuthLayout from './AuthLayout';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

class OTPConfirmationPage extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OTPVerifyCodeModel({
        hash: props.hash,
        token: props.token,
        input: ''
      }),
      {
        timer: props.timer,
        retries: props.retries,
        resendLoading: false,
        request: props.request,
        redirecting: false,
        submitLoading: false
      }
    );
    this.timer = 0;

    this.formHandler.on('submitSuccess', data => {
      // this.onSubmitSuccess(data);
    });
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { timer } = this.state;

      if (timer > 0) {
        this.setState(({ timer }) => ({
          timer: timer - 1
        }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  onBeforeSubmit() {
    this.setState({ submitLoading: true });
  }

  onSubmitSuccess(data) {
    if (data.success) {
      // Dispatch otp action based on response
      const { nextState } = data.data || {};
      if (nextState && nextState.name !== 'INPUT_WRONG') {
        this.props.onSuccess(nextState);
      }
    } else {
      this.setState({ submitLoading: false });
    }
  }

  onSubmitError(data) {
    this.setState({ submitLoading: false });
  }

  _getModalMessage(data) {
    if (data.success) {
      const { nextState } = data.data;
      if (nextState.name === 'INPUT_WRONG') {
        this.setState({ submitLoading: false });
        if (this.state.request === 'email') {
          return 'Please enter a valid email address';
        }
        return 'Please enter a valid mobile phone number';
      }
    }
    if (!data.success && data.errors && data.errors.length) {
      this.setState({ submitLoading: false });
      return data.errors[0].message;
    }

    if (!data.success && data.message) {
      this.setState({ submitLoading: false });
      return data.message;
    }
  }

  async handlePasscodeResend() {
    this.setState({
      resendLoading: true
    });
    // Trigger sending passcode again
    let response;
    if (this.props.requestValue) {
      response = await Api.call('otp/verify', {
        token: this.props.resendPasscodeToken,
        hash: this.props.hash,
        input: this.props.requestValue
      });

      if (response.success) {
        this.setState(prevState => ({
          record: {
            ...prevState.record,
            token: response.data.nextState.payload.token,
            hash: response.data.nextState.payload.hash
          },
          retries: prevState.retries - 1,
          timer: this.props.timer
        }));
      }
    } else {
      response = await Api.call('otp/resend', {
        token: this.props.resendPasscodeToken,
        hash: this.props.hash,
        customerId: this.props.customerId
      });

      if (response.success) {
        this.setState(prevState => ({
          record: {
            ...prevState.record,
            token: response.data.token,
            hash: this.props.hash
          },
          retries: prevState.retries - 1,
          timer: this.props.timer
        }));
      }
      ToastHelper.success('You have successfully sent new password.');
    }

    this.setState({ resendLoading: false });
  }

  _resetForm() {
    let formHandler = this.formHandler;

    return (
      <div style={{ marginTop: 10 }}>
        <h4
          className="login-title"
          style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
        >
          <Trans>Sign in</Trans>
        </h4>
        <div className="login-box" id="log-in">
          <div style={{ marginTop: 10 }}>
            <p style={{ marginBottom: 20 }}>
              <Trans>
                {this.props.message ||
                  "We've sent a One Time Password to your registered email address."}
              </Trans>
            </p>
          </div>
        </div>
        <FormSubmitWrapper
          formHandler={formHandler}
          buttonClass="gold-button"
          to="/"
          buttonText={i18n.t('Submit')}
          getModalMessage={data => this._getModalMessage(data)}
          customLoading={this.state.submitLoading}
          // click={() => this.setState({ submitLoading: true })}
        >
          <div className="login-input-wrapper">
            <InputField
              className={'input-group'}
              materialProps={{
                fullWidth: true
              }}
              label=""
              name="input"
              placeholder={i18n.t('One Time Password')}
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.record.input}
              handler={formHandler}
            />
          </div>
        </FormSubmitWrapper>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/"></Redirect>;
    }
    return (
      <AuthLayout>
        {this._resetForm()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 100,
            width: '100%'
          }}
        >
          <div style={{ margin: 20 }}>
            {this.state.timer > 0 ? (
              <p>
                <Trans>You can resend the password in </Trans>
                {this.state.timer}
              </p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <AppButton
                  isLoading={this.state.resendLoading}
                  onClick={() => this.handlePasscodeResend()}
                  style={{ maxWidth: 500 }}
                >
                  <Trans>Resend password</Trans>
                </AppButton>
              </div>
            )}
          </div>
          <p>
            <Trans>Return to </Trans>
            <Link
              to="/login"
              style={{
                color: '#ad8a4e',
                textDecoration: 'none',
                fontWeight: 'bold',
                fontSize: 16
              }}
            >
              <Trans>Sign In</Trans>
            </Link>
          </p>
        </div>
      </AuthLayout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userLoggedIn: (token, account) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(OTPConfirmationPage);
