import React, { Component } from 'react';
import { FormSubmitWrapper, FormHandler, InputField } from '@pdl/app';
import { Redirect, Link } from 'react-router-dom';
import ForgotPasswordModel from '../models/ForgotPasswordModel';
import ToastHelper from '@pdl/app/util/ToastHelper';
import { Trans } from 'react-i18next';
import i18n from 'i18n';

export default class ForgotPassword extends Component<any, any> {
  formHandler: FormHandler;

  componentDidMount() {
    this.setState({
      record: {
        ...this.state.record,
        email: '',
        remember: false
      }
    });
  }

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        email: ' '
      }),
      {
        redirect: false
      }
    );
    this.formHandler.on('submitSuccess', (data: { success: any }) => {
      if (data.success) {
        ToastHelper.success('Please check your mailbox');
        setTimeout(() => {
          this.setState({
            redirect: true
          });
        }, 3000);
      }
    });
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { formHandler } = this;
    return (
      <div>
        <div className="verification-image" />
        <div className="container login-container">
          <img
            src={require('@pdl/app/assets/img/pdl-logo.svg')}
            className="login-logo"
            style={{ maxWidth: 200 }}
            alt="logo"
          />
          <div className="row">
            <div className="col-md login-holder">
              <h4
                className="login-title"
                style={{ color: '#000', textAlign: 'left', fontSize: 50 }}
              >
                <Trans>Forgot your password</Trans>
              </h4>
              <div className="login-box" id="log-in">
                <div style={{ marginTop: 10 }}>
                  <p style={{ marginBottom: 20 }}>
                    <Trans>
                      Don’t worry. We’ll reset your password and send you a link
                      to create a new one.
                    </Trans>
                  </p>
                  <FormSubmitWrapper
                    formHandler={formHandler}
                    buttonClass="gold-button"
                    to="/"
                    buttonText={i18n.t('Reset password')}
                    getModalMessage={data => this._getModalMessage(data)}
                  >
                    <div className="login-input-wrapper">
                      <InputField
                        className="input-group"
                        materialProps={{
                          fullWidth: true
                        }}
                        autoComplete={this.state.record.email}
                        label={i18n.t('Email address')}
                        type="email"
                        name="email"
                        // placeholder={'Email address'}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={this.state.record.email}
                        handler={formHandler}
                      />
                    </div>
                  </FormSubmitWrapper>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 100,
                    width: '100%'
                  }}
                >
                  <p>
                    <Trans>Return to </Trans>
                    <Link
                      to="/login"
                      style={{
                        color: '#ad8a4e',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      <Trans>Sign In</Trans>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
