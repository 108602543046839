import React from 'react';
// import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { AppProgressBar, AppIcon, AppButton, InputField } from '@pdl/app';
import Api from '@pdl/app/logic/api/Api';
import Config from '@pdl/app/config';
import _ from 'lodash';
import Auth from '@pdl/app/logic/services/AuthService';

const STATUS_OLD = 'old';
const STATUS_NEW = 'new';
const STATUS_UPLOADING = 'uploading';
const STATUS_QUEUED = 'queued';
const STATUS_REMOVED = 'removed';

export default class AppUploadField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(
      ([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(
        ([key, val]) =>
          prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      const { files } = event.target;
      const reader = new FileReader();
      reader.onload = e => {
        this._addFiles(files);
        // this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleDrop = acceptedFiles => {
    this._addFiles(acceptedFiles);
  };

  _addFiles(acceptedFiles) {
    const parsedFiles = [];

    acceptedFiles.forEach(file => {
      parsedFiles.push({
        id: _.uniqueId('prefix-'),
        file,
        name: file.name,
        path: file.preview,
        status: STATUS_QUEUED,
        progress: 0,
        value: null
      });
    });
    if (!this.props.multiple) {
      this.setState(
        {
          files: [parsedFiles[0]]
        },
        () => this._triggerUpload()
      );
    } else {
      this.setState(
        {
          files: [...this.state.files, ...parsedFiles]
        },
        () => this._triggerUpload()
      );
    }
  }

  _triggerUpload() {
    this.state.files.forEach(file => {
      if (file.status == STATUS_QUEUED) {
        this._startUploading(file);
      }
    });
  }

  async _startUploading(file) {
    const data = new FormData();
    data.append('file', file.file);
    data.append('filename', file.name);
    data.append('private', true);

    this._updateFile(file, {
      status: STATUS_UPLOADING
    });

    Api.call('tools/upload', data, progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      this._updateFile(file, {
        progress: percentCompleted
      });
    })
      .then(response => {
        if (response.success) {
          this._updateFile(file, {
            value: response.data.name,
            // path: this._parseFileUri(response.data.preview),
            status: STATUS_NEW,
            fileId: response.data.id
          });
        }
      })
      .catch(error => {
        alert(error.message);
        this._removeFile(file);
      });
  }

  _parseFileUri(uri) {
    if (uri[0] === '/') {
      return Config.publicUrl + uri;
    }

    return uri;
  }

  _updateFile(file, data) {
    const files = [...this.state.files];

    const foundFile = files.find(x => x.id === file.id);

    if (foundFile) {
      const index = files.indexOf(foundFile);
      const newFile = {
        ...foundFile,
        ...data
      };
      files[index] = newFile;
      this.setState(
        {
          files
        },
        () => this._afterFilesUpdate()
      );
    }
  }

  _removeFile(file) {
    const files = [...this.state.files];
    const index = files.indexOf(files.find(x => x.id === file.id));

    if (index >= 0) {
      files.splice(index, 1);
      this.setState(
        {
          files
        },
        () => this._afterFilesUpdate()
      );
    }
  }

  _afterFilesUpdate() {
    if (this.props.handler) {
      if (!this.props.multiple) {
        this.props.handler.handleInputChange(
          this.props.name,
          this.state.files[0] ? this.state.files[0].value : null
        );
      }
    }

    if (this.props.onChange) {
      this.props.onChange(this.state.files);
    }
  }

  _getDropzone() {
    if (!this.props.multiple && this.state.files.length > 0) return null;

    return (
      <Dropzone
        accept=".jpg,.jpeg,.png"
        onDrop={this.handleDrop}
        multiple={this.props.multiple}
        style={{
          display: 'inline-block',
          padding: 0,
          textAlign: 'center',
          borderRadius: 10
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative'
            }}
            className="uploadButton"
          >
            {this.props.children}
            {/* <button type="file" value='upload' className="redButton" style={{background: 'white', borderRadius: 50, color: 'black'}}>BROWSE </button> */}
            <button
              type="file"
              value="upload"
              className="redButton"
              style={{ background: 'white', borderRadius: 50, color: 'black' }}
            >
              {this.props.buttonLabel}
            </button>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    );
  }

  _getUploadedFiles() {
    if (!this.state.files.length) return null;

    const elements = [];

    const UploadedFile = props => this._getUploadedFileItem(props.file);

    this.state.files.forEach((file, index) => {
      elements.push(<UploadedFile file={file} key={index} />);
    });

    return elements;
  }

  _getUploadedFileItem(file) {
    let bottomElement = null;

    if (file.status == STATUS_UPLOADING) {
      bottomElement = (
        <div style={{ marginTop: 10 }}>
          <AppProgressBar percent={file.progress} />
        </div>
      );
    } else {
      bottomElement = (
        <div style={{ marginTop: 10 }}>
          <a href="javascript:void(0)" onClick={() => this._removeFile(file)}>
            Remove
          </a>
        </div>
      );
    }

    return (
      <div
        className="file-preview"
        style={{
          display: 'inline-block',
          border: '1px solid #e0e0e0',
          padding: 10,
          textAlign: 'center'
        }}
      >
        <p>{file.name}</p>
        <img
          src={file.path}
          style={{ height: 'auto', maxWidth: 200, maxHeight: 400 }}
          alt=""
        />
        {bottomElement}
      </div>
    );
  }

  render() {
    const dropzone = this._getDropzone();
    const uploadedFiles = this._getUploadedFiles();
    return (
      <div style={{}}>
        <label style={{ color: 'gray' }}>{this.props.label}</label>
        <div>
          {dropzone}
          {uploadedFiles}
        </div>
      </div>
    );
  }
}

AppUploadField.defaultProps = {
  // label: '*Upload the main project image',
  accept: '.jpg,.jpeg,.png',
  multiple: false
};
