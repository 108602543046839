import React from 'react';
import LoadingHelper from '@pdl/app/util/LoadingHelper';
import { AppList } from '@pdl/app';
import ConsentModel from '../consent/models/ConsentModel';
import AuthService from '@pdl/app/logic/services/AuthService';
import DateHelper from '@pdl/app/util/DateHelper';
import i18n from 'i18n';
import { Trans } from 'react-i18next';

export class ThirdPartyAccessList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePass: true
    };
  }

  renderConsentItem(item, index) {
    return (
      <div className="logDataRow" key={`item${index}`}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            textAlign: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ flex: 1 }}>
            <p>{item.tppName}</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>
              <Trans>{item.status}</Trans>
            </p>
          </div>
          <div style={{ flex: 1 }}>
            <p>{DateHelper.fullDate(item.createdAt)}</p>
          </div>
          <div style={{ flex: 1 }}>
            {item.tokenExpiresAt && (
              <p>{DateHelper.fullDate(item.tokenExpiresAt)}</p>
            )}
          </div>
          <div>
            <div>
              {item.status === 'confirmed' ? (
                <button
                  className="gold-button"
                  style={{ padding: '5px 30px', backgroundColor: '#EA9600' }}
                  onClick={() => this._revokeConsent(item.sessionSecret)}
                >
                  <Trans>REVOKE</Trans>
                </button>
              ) : (
                <button
                  style={{
                    padding: '5px 40px',
                    backgroundColor: '#afafaf',
                    color: 'white',
                    cursor: 'default',
                    border: 'none'
                  }}
                >
                  <Trans>REVOKE</Trans>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  _revokeConsent(id) {
    LoadingHelper.executeAction(
      () => {
        return new ConsentModel().revokeConsent(id);
      },
      {
        successMessage: i18n.t('Consent successfully revoked!'),
        loadingMessage: i18n.t('Revoking consent'),
        failureMessage: i18n.t('An error ocurred, please try again later'),
        beforeClose: () => {
          AuthService.refreshAuth();
          this._refreshConsentList();
        }
      }
    );
  }

  _refreshConsentList() {
    if (this._list) {
      this._list._reset().then(() => {
        this._list._refresh();
      });
    }
  }

  render() {
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <div
          className="row"
          style={{
            padding: '0px 0 30px 0',
            width: '100%',
            margin: 0,
            position: 'relative'
          }}
        >
          {/* <div className="card">{this.getAllLogData()}</div> */}
          <div className="card">
            <div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              >
                <div style={{ flex: 1 }}>
                  <p>
                    <Trans>TPP NAME</Trans>
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p>
                    <Trans>STATUS</Trans>
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p>
                    <Trans>CREATED AT</Trans>
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <p>
                    <Trans>EXPIRES AT</Trans>
                  </p>
                </div>
                <div style={{ minWidth: 150, textAlign: 'center' }}>
                  <p>
                    <Trans>ACTION</Trans>
                  </p>
                </div>
              </div>
            </div>
            <AppList
              onInit={list => (this._list = list)}
              model={ConsentModel}
              params={{
                limit: 10
              }}
              renderItem={(item, index) => this.renderConsentItem(item, index)}
              renderNoItems={() => (
                <div className="col-sm-12 well text-center">
                  <div style={{ margin: '20px', color: '#EA9600' }}>
                    <Trans>No new consent</Trans>
                  </div>
                </div>
              )}
              renderShowMore={(data, cb) => {
                return (
                  <button
                    className="showMoreNotification"
                    onClick={() => cb()}
                    style={{
                      margin: 'auto',
                      paddingTop: 20,
                      color: '#EA9600'
                    }}
                  >
                    <Trans>Load more consents</Trans>
                    <i
                      className="fa fa-chevron-down "
                      aria-hidden={true}
                      style={{ color: '#EA9600', fontSize: 16 }}
                    ></i>
                  </button>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ThirdPartyAccessList;
