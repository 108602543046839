import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const AuthLayout = props => {
  return (
    <div>
      <div className="verification-image" />
      <div className="signUp-wrapper">
        <Link
          to="/sign-up"
          style={{
            color: '#fff',
            textDecoration: 'none',
            fontSize: 14,
            textAlign: 'center'
          }}
        >
          <Trans>New to Prepaid Deluxe? Sign Up</Trans>
        </Link>
      </div>
      <div className="container login-container">
        <img
          src={require('@pdl/app/assets/img/pdl-logo.svg')}
          className="login-logo"
          style={{ maxWidth: 200 }}
          alt="logo"
        />
        <div className="row">
          <div className="col-md login-holder">
            <div className="login-box" id="log-in">
              <div style={{ marginTop: 10 }}>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
