import React, { Component } from 'react';
import NumberHelper from '@pdl/app/util/NumberHelper';
import DateHelper from '@pdl/app/util/DateHelper';
import ModalService from '../../../../logic/services/ModalService';
import { Trans } from 'react-i18next';

export default class StandardTransaction extends React.PureComponent {
  render() {
    const item = this.props.transaction;
    const iconBad = item.categoryIcon;
    let icon = null;
    if (iconBad) {
      icon = iconBad.replace('.', '-');
    }
    if (iconBad === 'info') {
      icon = `fa-${iconBad}`;
    }

    if (item.status === 'failed') {
      icon = 'fa-times';
    }

    let amount = null;
    let refund = null;
    let failed = null;
    let feeElement = null;
    const isFX =
      item.originalAmountCurrency &&
      item.originalAmountCurrency !== item.amountCurrency;
    let fxElements = null;
    const extraClasses = [];

    if (item.type === 'refund') {
      extraClasses.push('refund');
      refund = (
        <div>
          <span style={{ padding: 0, color: '#c3251f' }}>
            <Trans>refund</Trans>
          </span>
        </div>
      );
    }
    if (item.status === 'declined') {
      extraClasses.push('declined');
      failed = (
        <div className="transaction-declined">
          <span style={{ padding: 0, color: '#c3251f' }}>
            <Trans>declined</Trans>
          </span>
        </div>
      );
    }

    const transactionAmount = item.amount !== 0 ? item.amount : item.fee;
    amount = (
      <span className="amount">
        {NumberHelper.currency(transactionAmount, item.amountCurrency)}
        {refund}
        {failed}
      </span>
    );

    if (NumberHelper.isNonZero(item.fee)) {
      let fee = NumberHelper.value(item.fee);

      if (isFX && item.status === 'settled') {
        fee = -1 * NumberHelper.value(item.fxPadding);
      }

      if (fee != 0) {
        feeElement = (
          <span
            style={{
              flex: 1,
              fontSize: 12,
              alignSelf: 'flex-end',
              textAlign: 'right'
            }}
          >
            <span className="fee-dash">-</span>{' '}
            {NumberHelper.currency(fee, item.amountCurrency)} fee
          </span>
        );
      }
    }

    if (item.amount === 0 && item.fee > 0) {
      feeElement = null;
    }

    if (isFX) {
      const rate = Math.abs(item.originalAmount / item.amount);
      fxElements = (
        <span
          style={{
            flex: 1,
            fontSize: 12,
            alignSelf: 'flex-end',
            textAlign: 'right'
          }}
        >
          {NumberHelper.currency(
            item.originalAmount,
            item.originalAmountCurrency
          )}{' '}
          ({NumberHelper.currency(1, true)} ={' '}
          {NumberHelper.currency(rate, item.originalAmountCurrency)})
        </span>
      );
    }

    return (
      <li
        className={`notificationItem transactionItem statements ${extraClasses.join(
          ' '
        )}`}
        onClick={() =>
          ModalService.showModal('TRANSACTION_INFO', { transaction: item })
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexGrow: 1
          }}
        >
          <span className="iconHolder" style={{ width: 40, height: 40 }}>
            <i
              className="fa fa-info"
              aria-hidden
              style={{ color: '#ad8a4e', fontSize: 21 }}
            />
          </span>
          <span
            className="transaction-title-wrapper"
            style={{ display: 'flex', flexDirection: 'column', paddingLeft: 3 }}
          >
            <span className="transaction-title">{item.note}</span>
            <span className="transaction-date">
              {DateHelper.date(item.transactionDate)}
            </span>
          </span>
        </div>
        <div className="transaction-amount-wrapper">
          {fxElements}
          {amount}
          {feeElement}
        </div>
      </li>
    );
  }
}
